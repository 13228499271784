import { IEntityCreateFunctionResult } from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';
import { IEntityCreateFunctionProps } from '~/common/modules/PlaceEntity/types';
import { API_URLS } from '~/utils/constants';
import { jwtExpired } from '~/utils/catcher';

export type IUploadFileFn = ({ file: File, token: string }) => Promise<string> | undefined;

export const uploadFile: IUploadFileFn = async ({ file, token }) => {
  if (!file || typeof file !== 'object') return file;

  const formData = new FormData();
  formData.append('file', file);

  const response = await axios
    .post(API_URLS.upload, formData, {
      headers: { 'Content-Type': 'multipart/form-data', authorization: token },
    })
    .catch(jwtExpired);

  return response.data.data;
};

export const createItemsFn = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const form = new FormData();
  if (data.logo) {
    form.append('logo', data.logo);
  }
  const dto = {
    addressEn: data.addressEn?.trim() || '',
    addressRu: data.addressRu?.trim() || '',
    longitude: data.longitude?.toString() || '',
    latitude: data.latitude?.toString() || '',
    nameEn: data.nameEn || '',
    nameRu: data.nameRu || '',
    url: data.url || '',
  };

  form.append('dto', new Blob([JSON.stringify(dto)], { type: 'application/json' }));

  const result = await axios.post(url, form, {
    headers: { 'Content-Type': `multipart/form-data`, authorization: token },
  });

  return { data: result.data };
};
