import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { minMax } from '~/utils/validators';
import { GoogleMaps } from '~/pages/place/components/GoogleMaps';
import { EntityFileUpload } from '~/common/components/EntityFileUpload';
import { ALLOWED_IMAGE_EXTENSIONS, MAX_IMAGE_SIZE } from '~/pages/constants';

export const COMPANY_FIELDS: IEntityField[] = [
  {
    type: 'custom',
    name: 'map',
    label: 'Map',
    component: (props) => <GoogleMaps {...props} />,
  },
  {
    type: 'custom',
    name: 'logo',
    label: 'Логотип',
    hideInList: true,
    options: {
      maxSize: MAX_IMAGE_SIZE,
      allowedMimeType: ALLOWED_IMAGE_EXTENSIONS,
    },
    component: (props) => <EntityFileUpload {...props} />,
  },
  {
    type: 'number',
    name: 'latitude',
    label: 'Широта',
    required: true,
  },
  {
    type: 'number',
    name: 'longitude',
    label: 'Долгота',
    required: true,
  },
  {
    type: 'string',
    name: 'addressRu',
    label: 'Адрес ру',
    required: true,
  },
  {
    type: 'string',
    name: 'addressEn',
    label: 'Адрес англ',
    required: true,
  },
  {
    type: 'string',
    name: 'nameRu',
    label: 'Название ру',
    required: true,
    validator: (val: string) =>
      minMax(0, 50)(' Максимальная длина должна быть не более 50 символов')(val),
  },
  {
    type: 'string',
    name: 'nameEn',
    label: 'Название англ',
    required: true,
    validator: (val: string) =>
      minMax(0, 50)('Максимальная длина должна быть не более 50 символов')(val),
  },
  {
    type: 'string',
    name: 'url',
    label: 'URL',
  },
];
