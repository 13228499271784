import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import styles from './styles.module.scss';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import PlaceEntity from '~/common/modules/PlaceEntity';

interface IProps extends IEntityFieldProps {
  entity: PlaceEntity;
}

const GoogleMaps: FC<IProps> = observer(({ entity }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);

  const center = {
    lat: Number(entity.editorData['latitude']) || Number(process.env.REACT_APP_MAP_LATITUDE),
    lng: Number(entity.editorData['longitude']) || Number(process.env.REACT_APP_MAP_LONGITUDE),
  };

  const getAddress = (
    language: string,
    field: string,
    latitude: string,
    longitude: string
  ): void => {
    Geocode.setLanguage(language);
    Geocode.fromLatLng(latitude, longitude).then((response) => {
      const address = response.results[0].formatted_address;
      entity.setCoordinateValues({ [field]: address });
    });
  };

  const onMapClickHandler = (e) => {
    const latitude = e.latLng.lat();
    const longitude = e.latLng.lng();
    entity.setCoordinateValues({ latitude, longitude });
    getAddress('en', 'addressEn', latitude, longitude);
    getAddress('ru', 'addressRu', latitude, longitude);
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className={styles.container}>
      <GoogleMap
        onClick={onMapClickHandler}
        mapContainerStyle={{
          width: '100%',
          height: '450px',
        }}
        center={center}
        zoom={12}
      ></GoogleMap>
    </div>
  );
});

export { GoogleMaps };
