import {
  IAuthLogoutFn,
  IJWTAuthRequestFn,
  IJWTTokenRefreshFn,
  UNAUTHORIZED,
} from 'icerockdev-admin-toolkit';

import { axios } from '~/utils/axios';
import CustomError from '~/utils/customError';
import { API_URLS } from '~/utils/constants';

export const authRequestFn: IJWTAuthRequestFn = async (email: string, password: string) => {
  const {
    data: { accessToken, refreshToken, user },
  } = await axios.post(API_URLS.authorize, { email: email.trim(), password });
  if (!accessToken || !refreshToken) {
    throw new CustomError('Error', 'Неправильный логин или пароль');
  }
  return {
    user,
    tokens: {
      access: accessToken,
      refresh: refreshToken,
    },
    error: '',
  };
};

export const tokenRefreshFn: IJWTTokenRefreshFn = async (refreshToken) => {
  try {
    const result = await axios.post(API_URLS.refresh, { refreshToken });
    if (!result?.data?.accessToken || !result?.data?.refreshToken) {
      throw new Error(UNAUTHORIZED);
    }
    return {
      access: result.data.accessToken,
      refresh: result.data.refreshToken,
    };
  } catch (e) {
    return {
      access: '',
      refresh: '',
    };
  }
};

export const authLogoutFn: IAuthLogoutFn = async () => {
  // Leave this function in case someone add logout in backend
};
