import {
  JWTAuthProvider,
  WithTokenFunction,
  UNAUTHORIZED,
  EMPTY_USER,
} from 'icerockdev-admin-toolkit';
import { action, flow } from 'mobx';
import Layout from '~/auth/components/Layout';
import { authLogoutFn, authRequestFn, tokenRefreshFn } from '~/auth/api';
import { SignIn } from '~/common/components/SignIn';
import { AuthRouter } from '~/auth/AuthRouter';

const EMPTY_TOKENS = {
  access: '',
  refresh: '',
};

class CustomJWTAuthProvider extends JWTAuthProvider {
  @action
  logout = () => {
    this.isLoading = true;
    this.user = EMPTY_USER;
    this.tokens = EMPTY_TOKENS;
    this.isLoading = false;
  };

  @action
  withToken: WithTokenFunction = async (req: any, args: any) => {
    return req({
      ...args,
      token: `Bearer ${this.tokens.access}`,
    })
      .then((result: any) => {
        if (result?.error === UNAUTHORIZED) {
          try {
            const tokens = this.tokenRefreshInstance;
            this.tokens = {
              access: tokens.access || '',
              refresh: tokens.refresh,
            };
          } catch (err) {
            this.user = EMPTY_USER;
            this.tokens = EMPTY_TOKENS;
            throw err;
          }
        }
        return result;
      })
      .catch(async (e: Error) => {
        if (!this.tokenRefreshFn) {
          console.warn('tokenRefreshFn not specified');
          throw new Error(e.message);
        }
        // If there's other updater, wait for it
        if (!this.tokenRefreshInstance) {
          this.tokenRefreshInstance = flow(function* (this: JWTAuthProvider) {
            if (!this.tokenRefreshFn) {
              return { access: '', refresh: '' };
            }

            return yield this.tokenRefreshFn(this.tokens.refresh);
          }).bind(this)();

          try {
            const tokens = await this.tokenRefreshFn(this.tokens.refresh);
            this.tokens = {
              access: tokens.access,
              refresh: tokens.refresh,
            };
          } catch (err) {
            this.user = EMPTY_USER;
            this.tokens = EMPTY_TOKENS;
            throw err;
          }
          this.tokenRefreshInstance = null;
        } else {
          await this.tokenRefreshInstance;
        }

        if (this.tokens.access && this.tokens.refresh) {
          return req({
            ...args,
            token: `Bearer ${this.tokens.access}`,
          });
        }

        this.user = EMPTY_USER;
        this.tokens = EMPTY_TOKENS;

        return e;
      });
  };
}

const authProvider = new CustomJWTAuthProvider({
  router: AuthRouter,
  layout: Layout,
  authRequestFn,
  tokenRefreshFn,
  authLogoutFn,
  loginLabel: 'Email',
  signIn: SignIn,
  getUserName: ({ user }) => `${user.firstName} ${user.lastName}`,
  getUserRoleTitle: (auth) => auth.user.email || '',
});

export default authProvider;
