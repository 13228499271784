import { Config } from 'icerockdev-admin-toolkit';
import company from '../pages/place/index';
import { VerticalLayout } from '~/common/components/VerticalLayout';
import auth from '~/auth';

export default new Config({
  layout: VerticalLayout,
  host: process.env.REACT_APP_BASE_URL,
  logo: '/images/img.png',
  auth: auth,
  pages: [company],
});
