/* eslint-disable no-shadow */

export const ONLY_LATIN_SYMBOLS = /^([a-zA-Z ]{0,500})$/;

export const LATIN_AND_SPEC_SYMBOLS = /[0-9A-Za-z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/;

export const API_URLS = {
  create: '/place/manual2',
  authorize: '/id/authorize',
  refresh: '/id/token',
  profile: '/id',
  upload: '/upload',
};

export const DEFAULT_MIN_STRING_LENGTH = 0;
export const DEFAULT_MAX_STRING_LENGTH = 70;

export enum DefaultStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

export const DEFAULT_STATUS_NAMES = {
  [DefaultStatus.Active]: 'Active',
  [DefaultStatus.Blocked]: 'Blocked',
};
