import { Entity, EntityList, EntityBreadcrumbs, IEntityProps } from 'icerockdev-admin-toolkit';
import { action, computed, reaction } from 'mobx';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import EmptyList from '~/common/components/EmptyList';
import { EntityFooter } from '~/common/components/EntityFooter';
import { EntityHead } from '~/common/components/EntityHead';
import { EntityViewer } from '~/common/components/EntityViewer';

export interface ICustomEntityProps extends IEntityProps {}

class CustomEntity extends Entity {
  emptyText = '';

  constructor(fields?: Partial<ICustomEntityProps>) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }
  }

  @action
  fetchItems = () => {
    this.fetchItemsCancel();
    this.isLoading = false;
  };

  @action
  onMount = () => {
    this.getFiltersFromHash();
    reaction(
      () => [this.filters, this.sortBy, this.sortDir, this.page, this.items],
      this.setFiltersWindowHash
    );
    reaction(() => [this.items, this.sortBy, this.sortDir], this.applyFilter);
    reaction(() => this.page, this.fetchItems);
    this.fetchItems();
  };

  @computed
  get CreatorBody() {
    this.fields.forEach((item) => this.resetFieldError(item.name));

    return observer(() => {
      if (!this.canCreate) return this.forbiddenPlaceholder;
      return (
        <EntityViewer
          fields={this.fields}
          errors={this.editorFieldErrors}
          url={this.menu.url}
          onSave={this.createItem}
          onCancel={this.onEditCancel}
          onResetFieldError={this.resetFieldError}
          isEditing
          isLoading={this.isLoading}
          setEditorData={this.setEditorData}
          data={this.editorData}
          getItem={this.createEmptyItem}
          cancelGetItem={this.getItemsCancel}
          viewable={this.viewable}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      );
    });
  }

  @computed
  get ListBody() {
    return observer(() =>
      this.data.length > 0 ? (
        <EntityList
          fields={this.fields}
          data={this.data}
          extra={this.ListExtra}
          isLoading={this.isLoading}
          url={this.menu.url}
          selected={this.selected}
          sortBy={this.sortBy}
          sortDir={this.sortDir}
          canView={this.viewable && this.canView}
          canEdit={this.editable && this.canEdit}
          canSelect={this.selectable}
          setSelected={this.setSelected}
          onSortChange={this.setSort}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      ) : (
        <EmptyList isLoading={this.isLoading} text={this.emptyText} />
      )
    );
  }

  @computed
  get ListFooter() {
    return observer(() =>
      this.data.length > 0 ? (
        <EntityFooter
          page={this.page}
          itemsPerPage={this.itemsPerPage}
          items={this.items}
          totalCount={this.totalCount}
          setPage={this.setPage}
          setPerPage={this.setPerPage}
        />
      ) : (
        <></>
      )
    );
  }

  @computed
  get Breadcrumbs() {
    return observer(
      ({
        id,
        isEditing = false,
        isCreating = false,
        buttons,
      }: {
        id?: any;
        isEditing?: boolean;
        isCreating?: boolean;
        buttons?: ReactElement;
      }) => (
        <EntityBreadcrumbs
          data={this.editorData}
          fields={this.fields}
          id={id}
          name={this.title}
          url={this.menu.url}
          isEditing={isEditing}
          isCreating={isCreating}
          buttons={buttons}
          viewable={this.viewable}
          editable={false}
        />
      )
    );
  }

  @computed
  get ListHead() {
    return observer(() => (
      <EntityHead
        clearData={this.createEmptyItem}
        filterData={this.filterData}
        title={<this.ListHeadTitle />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable && this.canExport}
        canCreate={this.creatable && this.canCreate}
        entity={this}
      />
    ));
  }
}

export default CustomEntity;
