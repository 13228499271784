import { API_URLS } from '~/utils/constants';

import { createItemsFn } from './api';
import { COMPANY_FIELDS } from '~/pages/place/fields';
import PlaceEntity from '~/common/modules/PlaceEntity';

export default new PlaceEntity({
  title: 'Place',
  viewable: true,
  editable: true,
  creatable: true,
  api: {
    create: { url: API_URLS.create, method: 'post' },
  },
  menu: {
    label: 'Place',
    url: '/place',
    enabled: true,
  },
  fields: COMPANY_FIELDS,
  createItemsFn,
});
