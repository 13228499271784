import React from 'react';
import CustomEntity from '~/common/modules/CustomEntity';
import { action, flow, toJS } from 'mobx';
import { Entity, IEntityProps } from 'icerockdev-admin-toolkit';
import { IEntityCreateFunction, IEntityCreateFunctionResult } from './types';

export interface ICustomEntityProps extends IEntityProps {
  createItemsFn?: IEntityCreateFunction;
}

class PlaceEntity extends CustomEntity {
  constructor(fields?: Partial<ICustomEntityProps>) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }
  }

  @action
  setCoordinateValues = (object) => {
    this.editorData = { ...this.editorData, ...object };
  };

  @action
  createItem = () => {
    this.updateItemInstance = flow(function* (this: Entity) {
      this.isLoading = true;
      this.error = '';

      try {
        const data = toJS(this.editorData);

        if (!this.validateSubmitFields(data, true)) {
          throw new Error('Проверьте все поля');
        }

        if (!this.api?.create?.url || !this.createItemsFn) {
          throw new Error('Невозможно загрузить');
        }

        const result: IEntityCreateFunctionResult = yield this.parent?.auth?.withToken(
          this.createItemsFn,
          {
            url: this.api?.create?.url || '',
            data,
          }
        );
        if (!result || result.error || result.message === 'Network Error') {
          throw new Error(result?.error || 'Невозможно загрузить');
        } else {
          this.isLoading = false;
          this.parent?.notifications.showSuccess('Место добавлено');
          this.editorData = {};
          this.parent?.history.push(this.menu.url);
        }
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };
}

export default PlaceEntity;
