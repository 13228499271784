export const PHONE_NUMBER_LENGTH = 11;

export enum UserStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

export const USER_STATUS_NAMES = {
  [UserStatus.Active]: 'Active',
  [UserStatus.Blocked]: 'Blocked',
};

export enum CompanyStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

export const COMPANY_STATUS = {
  [CompanyStatus.Active]: 'Active',
  [CompanyStatus.Blocked]: 'Blocked',
};

export enum UserRoles {
  admin = 'ADMIN',
  manager = 'MANAGER',
}

export const USER_ROLES = {
  [UserRoles.admin]: 'Admin',
  [UserRoles.manager]: 'Manager',
};

export const MAX_IMAGE_SIZE = 1024 * 1024 * 10;
export const ALLOWED_IMAGE_EXTENSIONS = ['image/png', 'image/jpg', 'image/jpeg'];
